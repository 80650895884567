import { useEffect } from "react";

import i18n, { localizedPath } from "@/translations/i18n.ts";

export const NotFound = () => {
  const languageCode = i18n.language;
  useEffect(() => {
    window.location.href = localizedPath[languageCode] || localizedPath["en"];
  }, [languageCode]);

  return <></>;
};
