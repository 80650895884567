import { ChatPage } from "@/routes/console-page";
import { NotFound } from "@/routes/not-found";
import { localizedPath } from "@/translations/i18n.ts";

export const makeRoutes = () => {
  return [
    ...Object.keys(localizedPath).map((lang) => ({
      path: localizedPath[lang],
      element: <ChatPage />,
      key: lang,
    })),
    {
      path: "*",
      element: <NotFound />,
    },
  ];
};
