import {
  ActionItemSelectedIcon,
  Menu,
  MenuItem,
  MenuPopover,
  MenuTrigger,
} from "@translated/babele-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Globe01Icon } from "@/assets/images/icons.tsx";
import { useNavigate } from "react-router-dom";
import { localizedPath } from "@/translations/i18n.ts";

export const languages = [
  {
    code: "en",
    label: "English",
  },
  {
    code: "de",
    label: "Deutsch",
  },
  {
    code: "es",
    label: "Español",
  },
  {
    code: "fr",
    label: "Français",
  },
  {
    code: "it",
    label: "Italiano",
  },
  {
    code: "ja",
    label: "日本語",
  },
  {
    code: "ko",
    label: "한국어",
  },
  {
    code: "pt-BR",
    label: "Português (BR)",
  },
  {
    code: "ru",
    label: "Русский",
  },
  {
    code: "zh-CN",
    label: "简体中文",
  },
  {
    code: "zh-TW",
    label: "繁體中文",
  },
];

export const ChangeLocaleMenu = ({
  onOpenChange,
}: {
  onOpenChange?: () => void;
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const handleChangeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);

    navigate(localizedPath[languageCode]);
    window.location.reload();
  };

  return (
    <Menu placement="bottom-end" onOpenChange={onOpenChange}>
      <MenuTrigger
        variant={{ mode: "ghost" }}
        className={clsx(
          "!p-0 transition-all duration-300 !text-neutral-25 hover:!bg-neutral-25/2",
        )}
        aria-label="Change language"
      >
        <Globe01Icon />
      </MenuTrigger>
      <MenuPopover className="text-neutral-1000 z-50">
        {languages.map((language) => {
          return (
            <MenuItem
              aria-selected={currentLanguage === language.code}
              key={language.code}
              onClick={() => handleChangeLanguage(language.code)} // Cambia lingua al click
              className="flex items-center gap-2 justify-between"
            >
              {language.label}
              <ActionItemSelectedIcon />
            </MenuItem>
          );
        })}
      </MenuPopover>
    </Menu>
  );
};
