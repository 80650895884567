import { Button } from "@translated/babele-react";
import { clsx } from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import callLaraBackground from "@/assets/images/callLaraBackground.png";
import { ConsecutiveIcon } from "@/assets/images/icons.tsx";
import useWindowDimensions from "@/hooks/useWindowDimensions.ts";

export const CallLara = ({
  connectConversation,
}: {
  connectConversation: () => void;
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  return (
    <>
      <div
        className={clsx(
          "h-full w-full rounded-xs relative",
          "flex flex-col items-center justify-center gap-2",
        )}
        style={{
          ...(!isMobile && {
            background: `url(${callLaraBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }),
        }}
      >
        <ConsecutiveIcon width={70} height={70} />
        <div
          className={clsx(
            "text-display-sm",
            "text-center",
            "font-regular",
            "text-neutral-1000",
            "mx-4",
          )}
        >
          {t("commons:interpreter_title")} <br />
          {!isMobile && t("commons:interpreter_subtitle")}
        </div>
        <p
          className={clsx(
            "text-md font-regular mt-2 mx-5",
            "text-center",
            "text-neutral-900",
          )}
        >
          {t("commons:interpreter_description")}
        </p>
        {!isMobile && (
          <Button
            onClick={connectConversation}
            variant={{
              tone: "primary",
              size: isMobile ? "xl" : "lg",
            }}
            className={clsx(
              "w-full  mt-4",
              "sm:max-w-[256px] max-w-[90%] ",
              "absolute bottom-8  sm:static sm:bottom-auto ",
            )}
          >
            {t("commons:call_lara")}
          </Button>
        )}
      </div>

      {isMobile && (
        <Button
          onClick={connectConversation}
          variant={{
            tone: "primary",
            size: isMobile ? "xl" : "lg",
          }}
          className={clsx(" w-auto m-4 self-center")}
        >
          {t("commons:call_lara")}
        </Button>
      )}
    </>
  );
};
