import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

//translations
import de from "./de/de.json";
import en from "./en/en.json";
import es from "./es/es.json";
import fr from "./fr/fr.json";
import it from "./it/it.json";
import ja from "./ja/ja.json";
import ko from "./ko/ko.json";
import pt from "./pt-BR/pt-BR.json";
import ru from "./ru/ru.json";
import zhCN from "./zh-CN/zh-CN.json";
import zhTW from "./zh-TW/zh-TW.json";

import "dayjs/locale/en";
import "dayjs/locale/it";
export const FALLBACK_LANGUAGE = "en";

export const APP_LANGUAGES = [
  { label: "English (EN)", code: "en", flagCode: "gb", json: en },
  { label: "Español (ES)", code: "es", flagCode: "es", json: es },
  { label: "Français (FR)", code: "fr", flagCode: "fr", json: fr },
  { label: "Italiano (IT)", code: "it", flagCode: "it", json: it },
  { label: "日本語 (JA)", code: "ja", flagCode: "jp", json: ja },
  { label: "한국어 (KO)", code: "ko", flagCode: "kr", json: ko },
  { label: "Português (BR)", code: "pt-BR", flagCode: "br", json: pt },
  { label: "Русский (RU)", code: "ru", flagCode: "ru", json: ru },
  { label: "Deutsch (DE)", code: "de", flagCode: "de", json: de },
  { label: "简体中文 (ZH-CN)", code: "zh-CN", flagCode: "cn", json: zhCN },
  { label: "繁體中文 (ZH-TW)", code: "zh-TW", flagCode: "tw", json: zhTW },
] as const;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: Object.fromEntries(
      APP_LANGUAGES.map((language) => [language.code, language.json]),
    ),
    ns: [],
    defaultNS: "",
    keySeparator: false,
    fallbackLng: FALLBACK_LANGUAGE,
    debug: false,
    react: {
      useSuspense: false,
    },
    supportedLngs: APP_LANGUAGES.map((language) => language.code),
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "i18nextLng",
    },
  });

export const changeAppLanguage = async (
  nextLanguage: (typeof APP_LANGUAGES)[number]["code"],
) => {
  await i18n.changeLanguage(nextLanguage);
};

export const i18nLanguages: Record<string, string> = {
  en: "english",
  es: "spanish",
  fr: "french",
  it: "italian",
  ja: "japanese",
  ko: "korean",
  pt: "portuguese",
  ru: "russian",
  de: "german",
  ["zh-CN"]: "chinese_simplified",
  ["zh-TW"]: "chinese_traditional",
} as const;

export const i18nLanguagesVariants: Record<string, string> = {
  "zh-CN": "chinese_simplified",
  "zh-HK": "chinese_traditional_hong_kong",
  "zh-MO": "chinese_traditional_macau",
  "zh-TW": "chinese_traditional_taiwan",

  "en-US": "english_usa",
  "en-GB": "english_great_britain",
  "en-AU": "english_australia",
  "en-CA": "english_canada",
  "en-IN": "english_india",
  "en-IE": "english_ireland",
  "en-NZ": "english_new_zealand",
  "en-SG": "english_singapore",
  "en-ZA": "english_south_africa",

  "fr-FR": "french_france",
  "fr-BE": "french_belgium",
  "fr-CA": "french_canada",
  "fr-CH": "french_switzerland",

  "de-DE": "german_germany",

  "it-IT": "italian_italy",
  "it-CH": "italian_switzerland",

  "ja-JP": "japanese_japan",

  "ko-KR": "korean_korea",

  "pt-BR": "portuguese_brazil",
  "pt-PT": "portuguese_portugal",

  "ru-RU": "russian_russia",

  "es-ES": "spanish_spain",
  "es-419": "spanish_latin_america",
  "es-AR": "spanish_argentina",
  "es-CO": "spanish_colombia",
  "es-MX": "spanish_mexico",
  "es-PE": "spanish_peru",
  "es-PR": "spanish_puerto_rico",
  "es-US": "spanish_usa",
  "es-VE": "spanish_venezuela",
} as const;

export const localizedPath = {
  en: "/translate/interpreter",
  de: "/übersetzer/dolmetscherin",
  es: "/traductor/interprete",
  fr: "/traduction/interprète",
  it: "/traduttore/interprete",
  ja: "/翻訳／通訳",
  ko: "/번역기/통역",
  ["pt-BR"]: "/tradutor/intérprete",
  ru: "/переводчик/устный-перевод",
  ["zh-CN"]: "/翻译/口译员",
  ["zh-TW"]: "/翻譯／口譯",
} as const as Record<string, string>;

export default i18n;
