import { ItemType } from "@/lib/sdk";
import React, { useContext } from "react";

import "./style.css";
import {
  CornerBubbleAssistantIcon,
  CornerBubbleUserIcon,
} from "@/assets/images/icons.tsx";
import { ControlPanel } from "@/context/ControlPanel.tsx";
import useDarkMode from "@/hooks/useDarkMode.ts";
import CustomAudioPlayer from "@/ui/AudioPlayer/AudioPlayer.tsx";
import { LogoRound } from "@/ui/Logo/LogoRound.tsx";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";

interface ConversationProps {
  items: ItemType[];
}

const Conversation: React.FC<ConversationProps> = ({ items }) => {
  const { showAudioPlayer } = useContext(ControlPanel);
  const { t } = useTranslation("translate");

  const isDarkMode = useDarkMode();

  return (
    <div data-conversation-content className={`body-wrapper`}>
      {
        // slice because we don't want to show the first item
        items.slice(1).map((conversationItem) => {
          return (
            <div
              className={`message-container ${conversationItem.role || ""}`}
              key={conversationItem.id}
            >
              {conversationItem.role === "assistant" && (
                <LogoRound
                  size={20}
                  isDarkMode={isDarkMode}
                  style={{
                    alignSelf: "self-end",
                  }}
                />
              )}
              <div
                className={clsx(
                  conversationItem.role == "user"
                    ? "flex flex-row-reverse"
                    : "flex",
                  "items-end",
                )}
              >
                <div>
                  {conversationItem.role == "user" ? (
                    <CornerBubbleUserIcon
                      style={{
                        transform: "translate(-1px, 1px)",
                      }}
                    />
                  ) : (
                    <CornerBubbleAssistantIcon />
                  )}
                </div>
                <div
                  className={`conversation-item ${conversationItem.role || ""}`}
                >
                  <div
                    className={`speaker ${conversationItem.role || ""}`}
                  ></div>
                  <div className={`speaker-content`}>
                    {/*tool response*/}
                    {conversationItem.type === "function_call_output" && (
                      <div>{conversationItem.formatted.output}</div>
                    )}
                    {/*tool call*/}
                    {!!conversationItem.formatted.tool && (
                      <div>
                        {conversationItem.formatted.tool.name}(
                        {conversationItem.formatted.tool.arguments})
                      </div>
                    )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === "user" && (
                        <div
                          className={
                            isDarkMode ? "text-neutral-25" : "text-neutral-1200"
                          }
                        >
                          {conversationItem.formatted.transcript ||
                            (conversationItem.formatted.audio?.length
                              ? t("commons:transcribing")
                              : conversationItem.formatted.text)}
                        </div>
                      )}
                    {!conversationItem.formatted.tool &&
                      conversationItem.role === "assistant" && (
                        <div>
                          {conversationItem.formatted.transcript ||
                            conversationItem.formatted.text}
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div></div>
              <div className={"conversation-info"}>
                {showAudioPlayer && conversationItem.formatted.file && (
                  <CustomAudioPlayer
                    audioSrc={conversationItem.formatted.file.url}
                    audioId={conversationItem.id}
                    isUserAudio={conversationItem.role === "user"}
                  />
                )}
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default Conversation;
