import clsx from "clsx";
import {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  LongPressEventType,
  LongPressReactEvents,
  useLongPress,
} from "use-long-press";

import useWindowDimensions from "@/hooks/useWindowDimensions.ts";

type PulseProps = {
  enabled: boolean;
  isLaraSpeaking: boolean;
  triggerAnimation: boolean;
  handleStopRecording: () => void;
  handleStartRecording: () => void;
  handleStopLara?: () => void;
  setTriggerAnimation: (value: boolean) => void;
  reachedLimit: boolean;
  setOpenDialogReachedLimit: (value: boolean) => void;
  disabled: boolean;
};

const Pulse = ({
  enabled = false,
  children,
  isLaraSpeaking,
  triggerAnimation,
  handleStopRecording,
  handleStartRecording,
  setTriggerAnimation,
  reachedLimit,
  setOpenDialogReachedLimit,
  disabled,
}: HTMLAttributes<HTMLElement> & PulseProps) => {
  const [lastMove, setLastMove] = useState<{ x: number; y: number } | null>(
    null,
  );
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  const handlePressStart = useCallback(() => {
    if (reachedLimit) {
      setOpenDialogReachedLimit(true);
    } else {
      if (!enabled) {
        handleStartRecording();
      }
      if (isLaraSpeaking) {
        setTriggerAnimation(true);
      }
    }
  }, [
    reachedLimit,
    setOpenDialogReachedLimit,
    isLaraSpeaking,
    enabled,
    handleStartRecording,
    setTriggerAnimation,
  ]);

  const handlePressEnd = useCallback(() => {
    if (!reachedLimit && enabled) {
      handleStopRecording();
    }
  }, [reachedLimit, setOpenDialogReachedLimit, enabled, handleStopRecording]);

  const handleMove = useCallback(
    (e: LongPressReactEvents<HTMLElement>) => {
      const mouseEvent = e as React.MouseEvent<HTMLElement>;

      if (lastMove) {
        const moveThreshold = 50;
        const deltaX = Math.abs(mouseEvent.clientX - lastMove.x);
        const deltaY = Math.abs(mouseEvent.clientY - lastMove.y);

        if (deltaX > moveThreshold || deltaY > moveThreshold) {
          handlePressEnd();
        }
      }

      setLastMove({ x: mouseEvent.clientX, y: mouseEvent.clientY });
    },
    [lastMove, handlePressEnd],
  );

  const bind = useLongPress(() => {}, {
    onStart: handlePressStart,
    onFinish: handlePressEnd,
    onCancel: handlePressEnd,
    onMove: handleMove,
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: 2000,
    cancelOutsideElement: true,
    detect: !isMobile ? LongPressEventType.Mouse : LongPressEventType.Touch,
  });

  useEffect(() => {
    if (triggerAnimation) {
      const timeout = setTimeout(() => {
        setTriggerAnimation(false);
      }, 2000);
      return () => clearTimeout(timeout);
    }
  }, [setTriggerAnimation, triggerAnimation]);

  return (
    <div
      aria-pressed={enabled}
      tabIndex={0}
      className={clsx(
        "pulse-container",
        isLaraSpeaking && "lara-speaking",
        enabled && "enabled",
        "transition-all duration-300",
        disabled ? "cursor-not-allowed opacity-1" : "cursor-pointer",
      )}
      {...bind()}
    >
      {children}

      {triggerAnimation && (
        <>
          <span className="circle circle-1" />
          <span className="circle circle-2" />
        </>
      )}

      {(enabled || isLaraSpeaking) &&
        Array.from({ length: 4 }).map((_, index) => (
          <span
            key={index}
            className="wave"
            style={{ animationDelay: `${index * 0.3}s` }}
          />
        ))}
    </div>
  );
};

export default Pulse;
