import {
  Menu,
  MenuItem,
  MenuPopover,
  MenuTrigger,
} from "@translated/babele-react";
import clsx from "clsx";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { MobileMenu } from "@/features/header/MobileMenu.tsx";
import { ChangeLocaleMenu } from "@/ui/ChangeLocaleMenu.tsx";
import { LaraLogoExtended } from "@/ui/LaraLogoExtended.tsx";

import { envVars } from "../../../envVars.ts";

const headerItemCommonStyle = clsx(
  "text-sm font-medium px-4 py-2.5 rounded-sm transition-all",
  "hover:ring-1 hover:ring-neutral-25 hover:ring-opacity-3",
  "focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-neutral-25 focus-visible:ring-opacity-3",
);

export function Header({
  user,
}: {
  user?: {
    name: string;
    avatar?: string;
  };
  detectedUserLanguage?: string;
  isLanguageSuggestionDismissed?: boolean;
}) {
  const { t } = useTranslation();
  const headerRef = useRef<HTMLDivElement>(null);

  const DesktopMenuItems = [
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:translate")}`,
      i18nLabel: "main_menu:translate",
      isActive: true,
    },
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:about")}`,
      i18nLabel: "main_menu:about",
      isActive: false,
    },
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:pricing")}`,
      i18nLabel: "main_menu:pricing",
      isActive: false,
    },
  ] as const;

  return (
    <>
      <header
        ref={headerRef}
        data-theme="translated-light"
        className={clsx(
          "text-neutral-25 grid md:grid-cols-[1fr_auto_1fr] grid-cols-[auto_1fr] items-center",
          "w-full mx-auto",
          "px-3 md:px-8 py-4",
        )}
      >
        <div className="flex items-center gap-1.5">
          <MobileMenu headerRef={headerRef} />
          <NavLink
            reloadDocument
            to={`${envVars.VITE_LARA_ENDPOINT}/${t(
              "main_menu:links:translate",
            )}`}
            className={"flex items-start gap-3 mr-auto outline-none"}
          >
            <LaraLogoExtended className="h-10 w-[6.75rem]" />
            <span
              className={clsx(
                "text-label-md font-medium py-0.5 px-1.5 opacity-8",
                "ring-1 rounded-md ring-neutral-700 bg-purple-400 bg-opacity-2",
              )}
            >
              beta
            </span>
          </NavLink>
        </div>

        <div className="items-center gap-1 md:flex hidden justify-center">
          {DesktopMenuItems.map((item, index) => (
            <NavLink
              key={index}
              to={item.to}
              className={({ isActive }) =>
                clsx(
                  item.isActive || isActive
                    ? "ring-1 ring-neutral-25 ring-opacity-2"
                    : "",
                  headerItemCommonStyle,
                )
              }
            >
              {t(item.i18nLabel)}
            </NavLink>
          ))}
          <button
            type="button"
            className={clsx(headerItemCommonStyle)}
            onClick={() => {
              if (Window.HubSpotConversations?.widget) {
                window.HubSpotConversations.widget.load();
                window.HubSpotConversations.widget.open();
              } else {
                console.error("HubSpot widget is not available.");
              }
            }}
          >
            {t("main_menu:support")}
          </button>
        </div>

        <div className="flex items-center justify-end gap-1 md:gap-4 relative">
          <ChangeLocaleMenu />

          {user ? (
            <Menu variant={{ size: "sm" }}>
              <MenuTrigger
                className={clsx(
                  user.avatar
                    ? `bg-[url('${user.avatar}')]`
                    : "bg-purple-400 enabled:hover:ring-opacity-1 enabled:hover:!bg-purple-100 enabled:hover:!bg-opacity-5 bg-opacity-5",
                  "h-10 w-10 flex items-center justify-center",
                  "rounded-xl ring-2 ring-neutral-25 ring-opacity-2",
                )}
              >
                {!user.avatar}
              </MenuTrigger>
              <MenuPopover
                className="p-2 grid text-neutral-1000 z-50 w-[15rem]"
                unmountOnHide={true}
              >
                <MenuItem>
                  <NavLink to="/account" className="flex items-center gap-2">
                    {t("account")}
                  </NavLink>
                </MenuItem>
                <MenuItem>
                  <NavLink
                    to="/account/invoices"
                    className="flex items-center gap-2"
                  >
                    {t("invoices")}
                  </NavLink>
                </MenuItem>
                <div className="h-[0.0625rem] w-full bg-neutral-200" />
                <MenuItem className="text-critical-1000">
                  {t("logout")}
                </MenuItem>
              </MenuPopover>
            </Menu>
          ) : (
            <></>
          )}
        </div>
      </header>
    </>
  );
}
