import { RealtimeEventHandler } from "./event_handler.js";
import { RealtimeUtils } from "./utils.js";

export class RealtimeAPI extends RealtimeEventHandler {
  /**
   * Create a new RealtimeAPI instance
   * @param {{url: string, debug?: boolean}} [settings]
   * @returns {RealtimeAPI}
   */
  constructor({ url, debug } = {}) {
    super();
    this.url = url;
    this.debug = !!debug;
    this.ws = null;
  }

  /**
   * Tells us whether the WebSocket is connected
   * @returns {boolean}
   */
  isConnected() {
    return !!this.ws;
  }

  /**
   * Writes WebSocket logs to console
   * @param  {...any} args
   * @returns void
   */
  log(...args) {
    const date = new Date().toISOString();
    const logs = [`[Websocket/${date}]`].concat(args).map((arg) => {
      if (typeof arg === "object" && arg !== null) {
        return JSON.stringify(arg, null, 2);
      } else {
        return arg;
      }
    });
    if (this.debug) {
      console.log(...logs);
    }
  }

  /**
   * Connects to Realtime API Websocket Server
   * @returns {Promise<void>}
   */
  async connect() {
    if (this.isConnected()) {
      throw new Error(`Already connected`);
    }

    const WebSocket = globalThis.WebSocket;
    const ws = new WebSocket(this.url);
    ws.addEventListener("message", (event) => {
      const message = JSON.parse(event.data);
      this.receive(message.type, message);
    });
    return new Promise((resolve, reject) => {
      const connectionErrorHandler = () => {
        this.disconnect(ws);
        reject(new Error(`Could not connect to "${this.url}"`));
      };
      ws.addEventListener("error", connectionErrorHandler);
      ws.addEventListener("open", () => {
        this.log(`Connected to "${this.url}"`);
        ws.removeEventListener("error", connectionErrorHandler);
        ws.addEventListener("error", () => {
          this.disconnect(ws);
          this.log(`Error, disconnected from "${this.url}"`);
          this.dispatch("close", { error: true });
        });
        ws.addEventListener("close", () => {
          this.disconnect(ws);
          this.log(`Disconnected from "${this.url}"`);
          this.dispatch("close", { error: false });
        });
        this.ws = ws;
        resolve();
      });
    });
  }

  /**
   * Disconnects from Realtime API server
   * @param {WebSocket} [ws]
   * @returns void
   */
  disconnect(ws) {
    if (!ws || this.ws === ws) {
      this.ws && this.ws.close();
      this.ws = null;
    }
  }

  /**
   * Receives an event from WebSocket and dispatches as "server.{eventName}" and "server.*" events
   * @param {string} eventName
   * @param {{[key: string]: any}} event
   * @returns void
   */
  receive(eventName, event) {
    this.log(`received:`, eventName, event);
    this.dispatch(`server.${eventName}`, event);
    this.dispatch("server.*", event);
  }

  /**
   * Sends an event to WebSocket and dispatches as "client.{eventName}" and "client.*" events
   * @param {string} eventName
   * @param {{[key: string]: any}} data
   * @returns string
   */
  send(eventName, data) {
    if (!this.isConnected()) {
      throw new Error(`RealtimeAPI is not connected`);
    }
    data = data || {};
    if (typeof data !== "object") {
      throw new Error(`data must be an object`);
    }

    const eventId = RealtimeUtils.generateId("evt_");
    const event = {
      event_id: eventId,
      type: eventName,
      ...data,
    };
    this.dispatch(`client.${eventName}`, event);
    this.dispatch("client.*", event);
    this.log(`sent:`, eventName, event);
    this.ws.send(JSON.stringify(event));

    return eventId;
  }

  /**
   * Sends a ping event to WebSocket
   * @returns void
   */
  ping() {
    if (!this.isConnected()) {
      throw new Error(`RealtimeAPI is not connected`);
    }
    const event = {
      type: "ping",
      ping: true,
    };
    this.ws.send(JSON.stringify(event));
  }
}
