import { Button, SpinnerIcon, VolumeMaxIcon } from "@translated/babele-react";
import { clsx } from "clsx";
import { useState, useRef, useEffect, useContext, useMemo } from "react";

import { PauseIcon } from "@/assets/images/icons.tsx";
import { ControlPanel } from "@/context/ControlPanel.tsx";
import useWindowDimensions from "@/hooks/useWindowDimensions.ts";

const CustomAudioPlayer = ({
                             audioSrc,
                             audioId,
                             isUserAudio,
                           }: {
  audioSrc: string;
  audioId: string;
  isUserAudio: boolean;
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const audioContextRef = useRef<AudioContext | null>(null);
  const gainNodeRef = useRef<GainNode | null>(null);
  const { currentPlayingAudioId, setCurrentPlayingAudioId } =
      useContext(ControlPanel);
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
      const source = audioContext.createMediaElementSource(audio);
      const gainNode = audioContext.createGain();

      // Set gain value
      gainNode.gain.value = isUserAudio ? 1.0 : isMobile ? 3.0 : 1.5;

      // Connect nodes
      source.connect(gainNode).connect(audioContext.destination);

      // Save references
      audioContextRef.current = audioContext;
      gainNodeRef.current = gainNode;

      // Warm up the AudioContext
      const warmup = () => {
        if (audioContext.state === "suspended") {
          audioContext.resume();
        }
      };

      warmup();

      return () => {
        if (audioContextRef.current) {
          audioContextRef.current.close();
        }
      };
    }
  }, [isMobile, isUserAudio]);

  useEffect(() => {
    if (currentPlayingAudioId !== audioId && isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    }
  }, [currentPlayingAudioId, audioId, isPlaying]);

  const togglePlayPause = async () => {
    const audio = audioRef.current;

    if (audio) {
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
        setCurrentPlayingAudioId(null);
      } else {
        if (!currentPlayingAudioId || currentPlayingAudioId === audioId) {
          setLoading(true);
          try {
            // Resume AudioContext
            if (audioContextRef.current?.state === "suspended") {
              await audioContextRef.current.resume();
            }

            // Set a small playback offset to skip initialization gaps
            audio.currentTime = 0.05;

            // Play audio
            await audio.play();
            setCurrentPlayingAudioId(audioId);
            setIsPlaying(true);
          } catch (error) {
            console.error("Error playing audio:", error);
          } finally {
            setLoading(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener("ended", () => {
        setIsPlaying(false);
        setCurrentPlayingAudioId(null);
      });
    }
  }, [setCurrentPlayingAudioId]);

  const disabledButton = useMemo(() => {
    return (
        (Boolean(currentPlayingAudioId) && currentPlayingAudioId !== audioId) ||
        loading
    );
  }, [currentPlayingAudioId, audioId, loading]);

  return (
      <div>
        {/* Hidden native audio element */}
        <audio
            ref={audioRef}
            src={audioSrc}
            preload="auto"
        />
        {/* Custom play/pause button */}
        <Button
            variant={{ mode: "ghost", size: "xs" }}
            onClick={togglePlayPause}
            aria-label={isPlaying ? "pause" : "play"}
            className={clsx(
                "!px-0",
                disabledButton ? "cursor-not-allowed" : "cursor-pointer",
            )}
            disabled={disabledButton}
        >
          {loading ? (
              <SpinnerIcon />
          ) : isPlaying ? (
              <PauseIcon className={clsx("w-4 h-4")} fill={"#8d8f91"} />
          ) : (
              <VolumeMaxIcon />
          )}
        </Button>
      </div>
  );
};

export default CustomAudioPlayer;
