import { z } from "zod";

const boolEnvVar = z
  .string()
  .optional()
  .transform((s) => s == "true");

export const envVars = z
  .object({
    VITE_IS_REACTQUERYDEVTOOLS_ENABLED: boolEnvVar,
    VITE_SENTRY_DNS: z.string(),
    VITE_LOCAL_RELAY_SERVER_URL: z.string(),
    VITE_LARA_ENDPOINT: z.string(),
    VITE_IS_HUBSPOT_ENABLED: boolEnvVar,
    VITE_HUBSPOT_PORTAL_ID: z.string(),
  })
  .parse(import.meta.env);
