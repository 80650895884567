import { useEffect, useState } from "react";

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const switchElement = document.getElementById("incognitoSwitch");

    if (switchElement) {
      // Inizializza il valore
      setIsDarkMode(switchElement.getAttribute("aria-checked") === "true");

      const handleAttributeChange = () => {
        setIsDarkMode(switchElement.getAttribute("aria-checked") === "true");
      };

      // Usa un MutationObserver per monitorare i cambiamenti dell'attributo
      const observer = new MutationObserver(handleAttributeChange);
      observer.observe(switchElement, {
        attributes: true,
        attributeFilter: ["aria-checked"],
      });

      // Pulizia per fermare l'osservatore quando il componente si smonta
      return () => observer.disconnect();
    }
  }, []);

  return isDarkMode;
};

export default useDarkMode;
