import React from "react";

export const LogoRound = ({
  isDarkMode,
  size = 40,
  props,
  style,
}: {
  size?: number;
  isDarkMode: boolean;
  props?: React.SVGProps<SVGSVGElement>;
  style?: React.CSSProperties;
}) => {
  const fill = isDarkMode ? "#FFFFFF" : "#29292D";
  return (
    <svg
      style={style}
      {...props}
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2.82339C29.4861 2.82339 37.1766 10.5139 37.1766 20C37.1766 29.4861 29.4861 37.1766 20 37.1766C10.5139 37.1766 2.82339 29.4861 2.82339 20C2.8352 10.5178 10.5178 2.8352 20 2.82339ZM20 0C8.95451 0 0 8.95452 0 20C0 31.0455 8.95451 40 20 40C31.0455 40 40 31.0455 40 20C40 8.95452 31.0415 0 20 0Z"
        fill={fill}
      />
      <path
        d="M25.5559 16.4868C26.7172 16.4868 27.6587 15.5454 27.6587 14.384C27.6587 13.2227 26.7172 12.2812 25.5559 12.2812C24.3946 12.2812 23.4531 13.2227 23.4531 14.384C23.4531 15.5454 24.3946 16.4868 25.5559 16.4868Z"
        fill={fill}
      />
      <path
        d="M13.6445 29.1826V11.2578H16.7672V26.3277H25.7414V29.1787H13.6445V29.1826Z"
        fill={fill}
      />
    </svg>
  );
};
