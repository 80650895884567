import clsx from "clsx";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ConsecutiveIcon,
  DocumentsIllustration,
  TranslateIllustration,
} from "@/assets/images/icons.tsx";
import useWindowDimensions from "@/hooks/useWindowDimensions.ts";
import { localizedPath } from "@/translations/i18n.ts";
import { ActionButton } from "@/ui/ActionButton.tsx";
import {
  CompactIncognitoSelector,
  FullIncognitoSelector,
} from "@/ui/IncognitoSelector.tsx";

export const ConversationHeader = () => {
  const [isOn, setIsOn] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;

  /*  const combobox = A.useComboboxStore({ resetValueOnHide: true });
  const menu = A.useMenuStore({ combobox });*/
  const handleToggle = () => {
    setIsOn(!isOn);
    const htmlElement = document.querySelector("body");
    htmlElement?.setAttribute(
      "data-theme",
      isOn ? "translated-light" : "translated-dark",
    );
  };

  return (
    <div
      className={clsx(
        isMobile ? "h-[81px]" : "h-[98px]",
        isMobile ? "p-2" : "p-4",
        "flex items-center justify-between",
        isMobile && "border-b-1 border-neutral-300",
      )}
    >
      <div className={"flex lg:gap-2 items-center"}>
        <ActionButton
          to={`https://lara.translated.com/${t("main_menu:links:translate")}`}
          illustration={<TranslateIllustration className="size-8 lg:size-10" />}
          label={t("navigation_menu:translate_text")}
        />

        <ActionButton
          to={`https://lara.translated.com/${t(
            "main_menu:links:translate",
          )}/${t("main_menu:links:translate_documents")}`}
          illustration={<DocumentsIllustration className="size-8 lg:size-10" />}
          label={t("navigation_menu:translate_documents")}
        />

        <ActionButton
          to={localizedPath[languageCode]}
          illustration={<ConsecutiveIcon className={`size-8 lg:size-10`} />}
          label={t("navigation_menu:interpreter")}
        />
      </div>

      <div className={"flex gap-2 items-center"}>
        <div className="items-center hidden lg:flex">
          <FullIncognitoSelector onValueChange={handleToggle} value={isOn} />
        </div>
        <div className="items-center flex lg:hidden">
          <CompactIncognitoSelector
            className={"flex"}
            onValueChange={handleToggle}
            value={isOn}
          />
        </div>
      </div>
    </div>
  );
};
