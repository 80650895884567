import { Button, XCloseIcon } from "@translated/babele-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { AlertIcon } from "@/assets/images/icons.tsx";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
} from "@/features/dialog/Dialog.tsx";

export const SessionExpiredBanner = ({
  open,
  setOpen,
  reconnectLara,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  reconnectLara: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog defaultOpen={false} open={open} onOpenChange={setOpen}>
      <DialogContent
        alignToEnd={false}
        className="max-w-[25rem] flex flex-col gap-1 p-2 md:rounded-sm !h-fit"
        aria-describedby={undefined}
        data-mobile-dialog
      >
        <div
          className={clsx(
            "grid grid-rows-[auto,1fr,auto]",
            "justify-items-center",
            "justify-center",
            "gap-4",
            "text-neutral-1000",
            "p-3",
          )}
        >
          <AlertIcon className={"w-[48px] h-[48px] text-critical-1200"} />

          <div
            className={
              "flex flex-col gap-1 justify-center items-center text-center"
            }
          >
            <p className={"text-md font-medium"}>
              {t("generic_errors:session_expired")}
            </p>
            <span className={"text-sm font-regular px-2"}>
              {t("generic_errors:session_expired_description")} <br />
              {t("badge:lara_is_beta_subtitle")}
            </span>
          </div>

          <Button
            onClick={reconnectLara}
            variant={{
              tone: "primary",
            }}
            className={clsx("w-full max-w-[256px]")}
          >
            {t("commons:call_lara")}
          </Button>
        </div>

        <DialogCloseButton
          className="right-2 absolute md:top-1.5 md:right-2"
          asChild
        >
          <Button
            aria-label={t("translation_mode.close_modal_aria_label")}
            variant={{ mode: "ghost", size: "md" }}
            className="!text-neutral-1000"
            onClick={() => setOpen(false)}
          >
            <XCloseIcon />
          </Button>
        </DialogCloseButton>
      </DialogContent>
    </Dialog>
  );
};
