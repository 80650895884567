export const LaraLogoExtended = ({ className }: { className?: string }) => (
  <svg
    width="108"
    height="40"
    viewBox="0 0 108 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_964_17216)">
      <path
        d="M20.002 2.82367C29.489 2.82367 37.1803 10.5149 37.1803 20.002C37.1803 29.489 29.489 37.1803 20.002 37.1803C10.5149 37.1803 2.82367 29.489 2.82367 20.002C2.83548 10.5189 10.5189 2.83548 20.002 2.82367ZM20.002 0C8.9554 0 0 8.9554 0 20.002C0 31.0485 8.9554 40.0039 20.002 40.0039C31.0485 40.0039 40.0039 31.0485 40.0039 20.002C40.0039 8.9554 31.0446 0 20.002 0Z"
        fill="white"
      />
      <path
        d="M52.4844 23.7747V5.85205H55.6073V20.9234H65.244V23.7747H52.4883H52.4844Z"
        fill="white"
      />
      <path
        d="M79.5086 23.9914C78.1893 23.9914 76.9803 23.3731 76.681 21.8924C75.5231 23.5622 73.6407 24.0978 71.9709 24.0978C69.0645 24.0978 67.127 22.5107 67.127 19.7382C67.127 16.2411 70.2224 15.7016 72.6443 15.4338C74.6882 15.2172 76.2241 15.0321 76.2241 13.6577C76.2241 12.2832 74.6646 11.854 73.3453 11.854C71.8921 11.854 70.2499 12.3384 68.9306 13.2796L67.7452 11.0467C69.4938 9.91641 71.3526 9.32568 73.558 9.32568C76.8976 9.32568 79.2368 10.6725 79.2368 14.0633V20.4943C79.2368 21.408 79.5322 21.7861 80.2332 21.7861C80.501 21.7861 80.8239 21.7309 81.0129 21.597L81.3359 23.6409C80.8515 23.8812 80.0442 23.9914 79.5046 23.9914H79.5086ZM76.2517 16.2687C75.4444 17.2926 74.2314 17.2926 72.727 17.5328C71.2227 17.7731 70.116 18.2063 70.116 19.687C70.116 21.1678 71.2463 21.7861 72.6995 21.7861C74.5583 21.7861 76.2517 20.7897 76.2517 18.4229V16.2687Z"
        fill="white"
      />
      <path
        d="M91.5939 12.7677C91.137 12.5236 90.6251 12.3385 89.9792 12.3385C88.1755 12.3385 86.5885 13.898 86.5885 16.5917V23.7788H83.627V9.64475H86.5885V11.9328C87.5297 10.4521 88.9277 9.37695 90.5699 9.37695C91.3497 9.37695 92.0507 9.59355 92.4524 9.86135L91.5899 12.7677H91.5939Z"
        fill="white"
      />
      <path
        d="M105.913 23.9914C104.594 23.9914 103.385 23.3731 103.085 21.8924C101.927 23.5622 100.045 24.0978 98.3752 24.0978C95.4688 24.0978 93.5312 22.5107 93.5312 19.7382C93.5312 16.2411 96.6266 15.7016 99.0486 15.4338C101.093 15.2172 102.628 15.0321 102.628 13.6577C102.628 12.2832 101.069 11.854 99.7496 11.854C98.2964 11.854 96.6542 12.3384 95.3349 13.2796L94.1495 11.0467C95.8981 9.91641 97.7569 9.32568 99.9623 9.32568C103.302 9.32568 105.641 10.6725 105.641 14.0633V20.4943C105.641 21.408 105.936 21.7861 106.637 21.7861C106.905 21.7861 107.228 21.7309 107.417 21.597L107.74 23.6409C107.256 23.8812 106.448 23.9914 105.909 23.9914H105.913ZM102.656 16.2687C101.849 17.2926 100.636 17.2926 99.1313 17.5328C97.6269 17.7731 96.5203 18.2063 96.5203 19.687C96.5203 21.1678 97.6506 21.7861 99.1038 21.7861C100.963 21.7861 102.656 20.7897 102.656 18.4229V16.2687Z"
        fill="white"
      />
      <path
        d="M53.2602 28.2407V30.7375C53.6973 30.1035 54.2762 29.8633 54.9103 29.8633C56.2296 29.8633 57.0408 30.9147 57.0408 32.2892C57.0408 33.6636 56.2217 34.7151 54.9103 34.7151C54.2762 34.7151 53.6973 34.4748 53.2602 33.8487V34.6087H52.4844V28.2407H53.2602ZM54.7567 34.0023C55.7373 34.0023 56.2099 33.2422 56.2099 32.297C56.2099 31.3519 55.7373 30.5839 54.7567 30.5839C53.7761 30.5839 53.2563 31.3519 53.2563 32.297C53.2563 33.2422 53.784 34.0023 54.7567 34.0023Z"
        fill="white"
      />
      <path
        d="M60.1681 34.8216C59.8098 35.7588 59.4356 36.2472 58.5692 36.2472C58.203 36.2472 57.8919 36.1566 57.6241 35.9636L57.9627 35.3926C58.1242 35.4989 58.3093 35.5698 58.5141 35.5698C58.9434 35.5698 59.156 35.2587 59.3332 34.8649L59.4593 34.5892L57.498 29.9697H58.392L59.8728 33.5732L61.1842 29.9697H62.023L60.1681 34.8216Z"
        fill="white"
      />
      <path
        d="M67.9577 34.4039C67.6821 34.6166 67.3434 34.715 66.9496 34.715C66.4061 34.715 65.9847 34.526 65.7366 34.1282C65.5948 33.9038 65.5397 33.6478 65.5397 33.2106V30.6154H64.7363V29.9735H65.5397V28.6699H66.3155V29.9735H67.812V30.6154H66.3155V33.2106C66.3155 33.7462 66.5558 34.014 67.0283 34.014C67.304 34.014 67.5009 33.9156 67.6269 33.8171L67.9577 34.4078V34.4039Z"
        fill="white"
      />
      <path
        d="M71.286 30.7886C71.1324 30.7099 70.967 30.6468 70.7504 30.6468C70.136 30.6468 69.5532 31.1903 69.5532 32.1906V34.6086H68.7773V29.9695H69.5532V30.7532C69.8643 30.253 70.329 29.8789 70.9 29.8789C71.1678 29.8789 71.4002 29.9577 71.5341 30.0482L71.282 30.7886H71.286Z"
        fill="white"
      />
      <path
        d="M76.5433 34.5891C76.3897 34.6521 76.1613 34.6796 76.0077 34.6796C75.5705 34.6796 75.1767 34.467 75.1058 33.9393C74.6963 34.526 74.0701 34.7151 73.5069 34.7151C72.5539 34.7151 71.9277 34.1874 71.9277 33.3052C71.9277 32.2262 72.8729 32.0017 73.5345 31.915C74.2473 31.8166 75.0074 31.8166 75.0074 31.2298C75.0074 30.7139 74.4442 30.5524 73.9559 30.5524C73.4676 30.5524 72.9398 30.7218 72.5106 31.0447L72.1522 30.454C72.6957 30.0799 73.2746 29.8672 74.0071 29.8672C75.0586 29.8672 75.799 30.3122 75.799 31.3834V33.5769C75.799 33.9156 75.9053 34.0771 76.1652 34.0771C76.2715 34.0771 76.3779 34.0495 76.4409 34.0062L76.5393 34.593L76.5433 34.5891ZM75.0231 32.0214C74.712 32.4152 74.2552 32.4034 73.6842 32.5018C73.1564 32.5924 72.7193 32.7145 72.7193 33.2855C72.7193 33.8566 73.1486 34.0692 73.6842 34.0692C74.3615 34.0692 75.0231 33.703 75.0231 32.8208V32.0174V32.0214Z"
        fill="white"
      />
      <path
        d="M77.3457 34.6088V29.9696H78.1216V30.7376C78.5036 30.1744 79.0313 29.8633 79.7086 29.8633C80.7089 29.8633 81.3312 30.5131 81.3312 31.6197V34.6088H80.5553V31.7969C80.5553 31.2456 80.386 30.5918 79.5196 30.5918C78.7241 30.5918 78.1176 31.1708 78.1176 32.0372V34.6048H77.3418L77.3457 34.6088Z"
        fill="white"
      />
      <path
        d="M84.3135 34.7151C83.4038 34.7151 82.6792 34.3568 82.2617 33.7267L82.8328 33.2974C83.1911 33.7897 83.7267 34.0456 84.3568 34.0456C84.9869 34.0456 85.4359 33.7975 85.4359 33.2974C85.4359 32.8248 85.0145 32.7342 84.1599 32.5728C83.2502 32.4034 82.5019 32.1356 82.5019 31.2259C82.5019 30.3162 83.3408 29.8594 84.2426 29.8594C85.1445 29.8594 85.7588 30.2532 86.1251 30.6352L85.6092 31.1275C85.2784 30.7455 84.7979 30.5289 84.2347 30.5289C83.7425 30.5289 83.2541 30.7061 83.2541 31.155C83.2541 31.6276 83.7819 31.7339 84.4041 31.8521C85.3335 32.0293 86.2156 32.2459 86.2156 33.2344C86.2156 34.1165 85.5107 34.7151 84.3135 34.7151Z"
        fill="white"
      />
      <path
        d="M87.3223 34.6087V28.2407H88.0981V34.6087H87.3223Z"
        fill="white"
      />
      <path
        d="M93.7718 34.5891C93.6182 34.6521 93.3898 34.6796 93.2362 34.6796C92.7991 34.6796 92.4052 34.467 92.3344 33.9393C91.9248 34.526 91.2986 34.7151 90.7355 34.7151C89.7824 34.7151 89.1562 34.1874 89.1562 33.3052C89.1562 32.2262 90.1014 32.0017 90.763 31.915C91.4758 31.8166 92.2359 31.8166 92.2359 31.2298C92.2359 30.7139 91.6727 30.5524 91.1844 30.5524C90.6961 30.5524 90.1684 30.7218 89.7391 31.0447L89.3807 30.454C89.9242 30.0799 90.5031 29.8672 91.2356 29.8672C92.2871 29.8672 93.0275 30.3122 93.0275 31.3834V33.5769C93.0275 33.9156 93.1338 34.0771 93.3937 34.0771C93.5001 34.0771 93.6064 34.0495 93.6694 34.0062L93.7679 34.593L93.7718 34.5891ZM92.2556 32.0214C91.9445 32.4152 91.4876 32.4034 90.9166 32.5018C90.3889 32.5924 89.9518 32.7145 89.9518 33.2855C89.9518 33.8566 90.381 34.0692 90.9166 34.0692C91.594 34.0692 92.2556 33.703 92.2556 32.8208V32.0174V32.0214Z"
        fill="white"
      />
      <path
        d="M97.2683 34.4039C96.9926 34.6166 96.6539 34.715 96.2601 34.715C95.7167 34.715 95.2953 34.526 95.0472 34.1282C94.9054 33.9038 94.8503 33.6478 94.8503 33.2106V30.6154H94.0469V29.9735H94.8503V28.6699H95.6261V29.9735H97.1226V30.6154H95.6261V33.2106C95.6261 33.7462 95.8663 34.014 96.3389 34.014C96.6146 34.014 96.8115 33.9156 96.9375 33.8171L97.2683 34.4078V34.4039Z"
        fill="white"
      />
      <path
        d="M102.259 32.4783H98.62C98.6633 33.4431 99.2698 34.0023 100.18 34.0023C100.731 34.0023 101.188 33.7897 101.554 33.3959L102.07 33.9039C101.597 34.4119 100.983 34.7151 100.16 34.7151C98.6515 34.7151 97.7891 33.6794 97.7891 32.305C97.7891 30.9306 98.6436 29.8594 100.065 29.8594C101.487 29.8594 102.279 30.8754 102.279 32.1711C102.279 32.3129 102.271 32.4113 102.259 32.4743V32.4783ZM101.475 31.8797C101.396 30.9896 100.79 30.5328 100.057 30.5328C99.2462 30.5328 98.7027 31.096 98.6397 31.8797H101.475Z"
        fill="white"
      />
      <path
        d="M106.812 34.6087V33.8487C106.375 34.4748 105.788 34.7151 105.162 34.7151C103.843 34.7151 103.031 33.6636 103.031 32.297C103.031 30.9305 103.843 29.8633 105.162 29.8633C105.796 29.8633 106.375 30.1114 106.812 30.7375V28.2407H107.588V34.6087H106.812ZM105.304 30.5839C104.331 30.5839 103.85 31.344 103.85 32.2892C103.85 33.2343 104.323 34.0023 105.304 34.0023C106.284 34.0023 106.812 33.2343 106.812 32.2892C106.812 31.344 106.284 30.5839 105.304 30.5839Z"
        fill="white"
      />
      <path
        d="M25.56 16.4892C26.7215 16.4892 27.663 15.5476 27.663 14.3862C27.663 13.2247 26.7215 12.2832 25.56 12.2832C24.3986 12.2832 23.457 13.2247 23.457 14.3862C23.457 15.5476 24.3986 16.4892 25.56 16.4892Z"
        fill="white"
      />
      <path
        d="M13.6465 29.1858V11.2593H16.7695V26.3307H25.7445V29.1819H13.6465V29.1858Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_964_17216">
        <rect width="107.744" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
