import { useEffect, useRef } from "react";
import { RealtimeClient } from "@/lib/sdk";

/**
 * Custom Hook per rilevare cambiamenti di navigazione e visibilità su dispositivi mobili.
 *
 * @param {Function} functionA - Funzione da eseguire su navigazioni specifiche (es. reload, ritorno alla pagina).
 * @param {boolean} isMobile - Flag che indica se il dispositivo è mobile.
 * @param setOpenSessionExpired
 * @param client
 * @param controlPanelConnected
 */
const useNavigationEvents = (
  functionA: () => void,
  isMobile: boolean,
  setOpenSessionExpired: (value: boolean) => void,
  client: RealtimeClient,
  controlPanelConnected: boolean,
) => {
  // Riferimenti per mantenere lo stato tra i re-render
  const isReloadingRef = useRef(false);
  const isNavigatingAwayRef = useRef(false);

  useEffect(() => {
    // Se non è un dispositivo mobile, non fare nulla
    if (!isMobile) {
      return;
    }

    /**
     * Funzione da eseguire quando si naviga via dalla pagina.
     */
    const handleBeforeUnload = () => {
      isNavigatingAwayRef.current = true;
      sessionStorage.setItem("isNavigatingAway", "true");
      functionA();
    };

    /**
     * Funzione da eseguire quando la pagina viene mostrata (dopo una navigazione o un reload).
     *
     * @param {Event} event - Evento pageshow.
     */
    const handlePageShow = (event: any) => {
      if (!client.isConnected() && controlPanelConnected) {
        setOpenSessionExpired(true);
        return;
      }

      if (event.persisted) {
        // La pagina è stata ripristinata dalla bfcache (back-forward cache)
        functionA();
      } else {
        // Determina il tipo di navigazione
        const navigationEntries = performance.getEntriesByType("navigation");
        let navigationType = "navigate";

        if (navigationEntries.length > 0) {
          navigationType = navigationEntries[0].entryType;
        } else if (performance.navigation) {
          switch (performance.navigation.type) {
            case performance.navigation.TYPE_RELOAD:
              navigationType = "reload";
              break;
            case performance.navigation.TYPE_BACK_FORWARD:
              navigationType = "back_forward";
              break;
            default:
              navigationType = "navigate";
          }
        }

        if (navigationType === "reload") {
          isReloadingRef.current = true;
          functionA();
        } else {
          // Verifica se si sta tornando dopo aver navigato via
          const wasNavigatingAway =
            sessionStorage.getItem("isNavigatingAway") === "true";
          if (wasNavigatingAway) {
            functionA();
            sessionStorage.removeItem("isNavigatingAway");
          }
        }
      }
    };

    /**
     * Funzione da eseguire quando lo stato di visibilità cambia.
     */
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!client.isConnected() && controlPanelConnected) {
          setOpenSessionExpired(true);
        }
      }
    };

    /**
     * Funzione da eseguire quando la finestra guadagna focus.
     */
    const handleFocus = () => {
      if (!client.isConnected() && controlPanelConnected) {
        setOpenSessionExpired(true);
      }
    };

    // Aggiunge gli event listeners
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pageshow", handlePageShow);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    // Rimuove gli event listeners al momento dello smontaggio del componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pageshow", handlePageShow);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
  }, [functionA, isMobile]); // Aggiungi isMobile alle dipendenze
};

export default useNavigationEvents;
