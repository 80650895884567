import { AlertCircleIcon, XCloseIcon } from "@translated/babele-react";
import clsx from "clsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useWindowDimensions from "@/hooks/useWindowDimensions.ts";

export const QualityAudioBanner = ({
  setAudioQualityBanner,
}: {
  setAudioQualityBanner: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  return (
    <div
      className={clsx(
        "absolute",
        "top-3/4",
        "left-1/2",
        "transform",
        "translate-x-[-50%]",
        isMobile ? "translate-y-[-175%]" : "translate-y-[-220%]",
        "grid grid-cols-[auto,1fr,auto] gap-3",
        "text-xs text-neutral-1000",
        "bg-critical-100",
        "border-1",
        "border-critical-200",
        "p-4",
        "rounded-sm",
        isMobile ? "w-[90%]" : "w-[50%]",
      )}
    >
      <AlertCircleIcon className={"w-6 h-6 text-critical-700"} />
      <div>
        <p>{t("badge:quality_audio_title")}</p>
        <p>{t("badge:quality_audio_description")}</p>
      </div>
      <XCloseIcon
        className={"w-6 h-6 text-neutral-600 cursor-pointer"}
        onClick={() => setAudioQualityBanner(false)}
      />
    </div>
  );
};
