import { Path } from "@sentry/react/types/types";
import { ActionItem, ActionItemText } from "@translated/babele-react";
import { ReactNode, useMemo } from "react";

import useWindowDimensions from "@/hooks/useWindowDimensions.ts";
import { TNavLink } from "@/ui/TNavLink.tsx";

export const ActionButton = ({
  to,
  illustration,
  label,
}: {
  to: Path | string;
  illustration: ReactNode;
  label: string;
}) => {
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  return (
    <TNavLink to={to} end>
      {({ isActive }) => {
        return (
          <ActionItem
            className="flex items-center gap-2 relative"
            style={{
              padding: isMobile ? "12px" : "12px 20px 12px 16px",
            }}
            variant={{ size: "md" }}
            aria-selected={isActive}
          >
            {illustration}
            <ActionItemText className="font-medium hidden lg:block">
              {label}
            </ActionItemText>
          </ActionItem>
        );
      }}
    </TNavLink>
  );
};
