import { VariantProps, cva } from "class-variance-authority";
import { clsx } from "clsx";
import { HTMLAttributes, forwardRef } from "react";

const badgeVariants = cva(clsx("inline-flex items-center", "font-medium"), {
  variants: {
    size: {
      lg: clsx("h-8 px-4", "rounded-lg", "text-sm", "gap-1.5"),
      md: clsx("h-6 px-3", "rounded-md", "text-sm", "gap-1.5"),
      sm: clsx("h-5 px-2.5", "rounded-md", "text-label-md", "gap-1"),
      xs: clsx("h-3 px-2", "rounded-md", "text-label-sm", "gap-1"),
    },
    mode: { outline: "border-1", subtle: null },
    tone: {
      gray: "text-neutral-1000",
      blue: "text-primary-1000",
      indigo: "text-indigo-1000",
      purple: "text-purple-1000",
      violet: "text-violet-1000",
      fuchsia: "text-fuchsia-1000",
      magenta: "text-magenta-1000",
      red: "text-critical-1000",
      orange: "text-orange-1000",
      amber: "text-notice-1000",
      yellow: "text-yellow-1000",
      lime: "text-lime-1000",
      moss: "text-moss-1000",
      green: "text-success-1000",
      celery: "text-celery-1000",
      teal: "text-teal-1000",
      cyan: "text-cyan-1000",
    },
  },
  compoundVariants: [
    { mode: "subtle", tone: "gray", className: "bg-neutral-100" },
    { mode: "subtle", tone: "blue", className: "bg-primary-100" },
    { mode: "subtle", tone: "indigo", className: "bg-indigo-100" },
    { mode: "subtle", tone: "purple", className: "bg-purple-100" },
    { mode: "subtle", tone: "violet", className: "bg-violet-100" },
    { mode: "subtle", tone: "fuchsia", className: "bg-fuchsia-100" },
    { mode: "subtle", tone: "magenta", className: "bg-magenta-100" },
    { mode: "subtle", tone: "red", className: "bg-critical-100" },
    { mode: "subtle", tone: "orange", className: "bg-orange-100" },
    { mode: "subtle", tone: "amber", className: "bg-notice-100" },
    { mode: "subtle", tone: "yellow", className: "bg-yellow-100" },
    { mode: "subtle", tone: "lime", className: "bg-lime-100" },
    { mode: "subtle", tone: "moss", className: "bg-moss-100" },
    { mode: "subtle", tone: "green", className: "bg-success-100" },
    { mode: "subtle", tone: "celery", className: "bg-celery-100" },
    { mode: "subtle", tone: "teal", className: "bg-teal-100" },
    { mode: "subtle", tone: "cyan", className: "bg-cyan-100" },

    { mode: "outline", tone: "gray", className: "border-neutral-400" },
    { mode: "outline", tone: "blue", className: "border-primary-400" },
    { mode: "outline", tone: "indigo", className: "border-indigo-400" },
    { mode: "outline", tone: "purple", className: "border-purple-400" },
    { mode: "outline", tone: "violet", className: "border-violet-400" },
    { mode: "outline", tone: "fuchsia", className: "border-fuchsia-400" },
    { mode: "outline", tone: "magenta", className: "border-magenta-400" },
    { mode: "outline", tone: "red", className: "border-critical-400" },
    { mode: "outline", tone: "orange", className: "border-orange-400" },
    { mode: "outline", tone: "amber", className: "border-notice-400" },
    { mode: "outline", tone: "yellow", className: "border-yellow-400" },
    { mode: "outline", tone: "lime", className: "border-lime-400" },
    { mode: "outline", tone: "moss", className: "border-moss-400" },
    { mode: "outline", tone: "green", className: "border-success-400" },
    { mode: "outline", tone: "celery", className: "border-celery-400" },
    { mode: "outline", tone: "teal", className: "border-teal-400" },
    { mode: "outline", tone: "cyan", className: "border-cyan-400" },
  ],
  defaultVariants: { size: "md", mode: "subtle", tone: "gray" },
});

export type BadgeVariants = VariantProps<typeof badgeVariants>;
export type BadgeProps = HTMLAttributes<HTMLSpanElement> & {
  variant?: BadgeVariants;
};

export const Badge = forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
  { variant, ...props },
  ref,
) {
  return (
    <span
      ref={ref}
      {...props}
      className={clsx(props.className, badgeVariants(variant))}
    />
  );
});
